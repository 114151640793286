import React, { createContext, useContext, useState } from "react"
import { defaultLocale } from "../constants/countries"
import { AppFunctionComponent } from "../types"

export interface ContextProps {
  locale: string
  pathPrefix: string
  setLocale: (value: string) => void
  setPathPrefix: (value: string) => void
}

const defaultState: ContextProps = {
  locale: defaultLocale.locale,
  pathPrefix: defaultLocale.pathPrefix,
  setLocale: () => null,
  setPathPrefix: () => null,
}
const LocalizationContext = createContext(defaultState)
const useLocalizationContext = () => useContext(LocalizationContext)

const LocalizationProvider: AppFunctionComponent = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale.locale)
  const [pathPrefix, setPathPrefix] = useState(defaultLocale.pathPrefix)

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        pathPrefix,
        setPathPrefix,
        setLocale,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export default LocalizationContext
export { LocalizationProvider, useLocalizationContext }
