import { createReduxHistoryContext } from "redux-first-history"
import { createBrowserHistory, createMemoryHistory } from "history"
import { globalHistory } from "@reach/router"

const initialHistory =
  typeof window === "undefined" ? createMemoryHistory() : createBrowserHistory()

export const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: initialHistory,
    reachGlobalHistory: globalHistory,
    savePreviousLocations: 1,
  })
