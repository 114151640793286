import { DefaultStateQueryQuery } from "../../../graphql-types"
import { RootState } from "../../store"
import { DefaultNavigationPresenter } from "./default-navigation.presenter"

export class DefaultStatePresenter {
  private navigationPresenter = new DefaultNavigationPresenter()

  serializeToObject(resource: DefaultStateQueryQuery): Partial<RootState> {
    return {
      navigation: this.navigationPresenter.serializeToObject(resource),
    }
  }
}
