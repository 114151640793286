import { RequestState, ReducerEvent } from "../context/request-handling.context"

export enum ActionTypes {
  REQUEST_STARTED = "REQUEST",
  REQUEST_SUCCEEDED = "REQUEST_SUCCEEDED",
  REQUEST_FAILED = "REQUEST_FAILED",
  SET_NOTIFICATION = "SET_NOTIFICATION",
  CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION",
}

const requestHandlingReducer = (
  state: RequestState,
  action: ReducerEvent
): RequestState => {
  switch (action.type) {
    case ActionTypes.REQUEST_STARTED:
      return {
        ...state,
        fetching: true,
        hasError: false,
        status: null,
        message: "",
      }
    case ActionTypes.REQUEST_SUCCEEDED:
      return {
        ...state,
        fetching: false,
        hasError: false,
        status: action.payload!.status,
        message: action.payload!.message,
      }
    case ActionTypes.REQUEST_FAILED:
      return {
        ...state,
        fetching: false,
        hasError: true,
        status: action.payload!.status,
        message: action.payload!.message,
      }
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        active: true,
        type: action.payload!.type,
        message: action.payload!.message,
      }
    case ActionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        active: false,
        type: "",
        message: "",
      }
    default:
      return state
  }
}

export default requestHandlingReducer
