import { combineReducers } from "@reduxjs/toolkit"
import { PersistState } from "redux-persist"

import { routerReducer } from "./router"
import { navigationReducer } from "../navigation/slices/navigation.slice"
import { authReducer } from "../auth"

export const rootReducer = combineReducers({
  auth: authReducer,
  router: routerReducer,
  navigation: navigationReducer,
})

export type RootState = ReturnType<typeof rootReducer> & {
  _persist: PersistState
}
