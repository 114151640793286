import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { AppFunctionComponent } from "../types"
import { Provider } from "react-redux"
import { DefaultStateQueryQuery } from "../../graphql-types"
import { initStore } from "../store"
import { PageLoaderProvider } from "../context/page-loader.context"
import { RequestHandlingContextProvider } from "../context/request-handling.context"
import { DefaultStatePresenter } from "./presenters/default-state.presenter"
import { LocalizationProvider } from "../context/localization.context"

const statePresenter = new DefaultStatePresenter()

interface Props {
  element: React.ReactNode
}

// The default query used to populate the data from graphql into store
export const defaultStateQuery = graphql`
  query DefaultStateQuery {
    allContentfulPageMeta {
      nodes {
        title
        page
        node_locale
      }
    }
  }
`

export const WrapRootElement: AppFunctionComponent<Props> = ({ element }) => {
  const ContextProviders: AppFunctionComponent<DefaultStateQueryQuery> = (
    props
  ) => {
    return (
      <Provider store={initStore(statePresenter.serializeToObject(props))}>
        <RequestHandlingContextProvider>
          <PageLoaderProvider>
            <LocalizationProvider>{element}</LocalizationProvider>
          </PageLoaderProvider>
        </RequestHandlingContextProvider>
      </Provider>
    )
  }

  return <StaticQuery query={defaultStateQuery} render={ContextProviders} />
}
