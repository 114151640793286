import { createLogger } from "redux-logger"
import { persistStore, persistReducer } from "redux-persist"
import createWebStorage from "redux-persist/lib/storage/createWebStorage"
import { reachify } from "redux-first-history"
import { History } from "history"
import { routerMiddleware, createReduxHistory } from "./router"
import { rootReducer, RootState } from "./reducers"
import {
  AnyAction,
  configureStore,
  EmptyObject,
  Middleware,
  Store,
  ThunkDispatch,
} from "@reduxjs/toolkit"
import { PersistPartial } from "redux-persist/lib/persistReducer"

let store: Store<RootState>
let history: History

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null)
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value)
    },
    removeItem(_key: any) {
      return Promise.resolve()
    },
  }
}

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage()

const persistConfig = {
  key: "root",
  storage,
  version: 9,
  whitelist: ["market", "order", "checkout", "analytics", "auth"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// DO NOT REMOVE: This Removes Redux Persist error on development and production compile

export const getStore = () => store!
export const destroyStore = () => {
  // @ts-ignore
  store = null
}
export const initStore = (initialState = {}) => {
  if (store) {
    if (process.env.NODE_ENV !== "production") {
      console.log("The store is already initialized")
    }
    return store
  }

  const logger = createLogger()
  let middleware: Middleware[] = [routerMiddleware]

  if (process.env.NODE_ENV !== "production") {
    middleware = [...middleware, logger]
  } else {
    middleware = [...middleware]
  }

  store = configureStore({
    preloadedState: initialState,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
  })
  history = createReduxHistory(store)
  // @ts-ignore
  reachify(history)
  persistStore(store)

  return store
}

export type HooksRootState = RootState
export type HooksAppDispatch = ThunkDispatch<
  EmptyObject & RootState & PersistPartial,
  undefined,
  AnyAction
>
