import { UserAttributes } from "../../internal-api/auth0"
import { User } from "../dto/user.object"

export class AuthPresenter {
  serializeToObject(resource: UserAttributes): User {
    return this.toObject(resource)
  }

  private toObject(item: UserAttributes): User {
    return {
      email: item.email!,
      name: item.name,
      nickname: item.nickname,
      avatar: item.picture,
      customerId: item.user_metadata.clid,
      newsletterSubscribed: item.user_metadata.newsletter,
      loginSource: item.user_metadata.source,
    }
  }
}
