import { DefaultStateQueryQuery } from "../../../graphql-types"
import { NavigationState } from "../../navigation/slices/navigation.interface"
import { keyBy, mapValues } from "lodash"
import { initialState } from "../../navigation/slices/navigation.slice"
import PageMetadata from "../../components/seo/page-metadata.interface"
import { polishLocale } from "../../constants/countries"

export class DefaultNavigationPresenter {
  serializeToObject(resources: DefaultStateQueryQuery): NavigationState {
    const pageMetas = resources.allContentfulPageMeta.nodes as PageMetadata[]
    const allTranslatedMetas = pageMetas.map((meta) => {
      if (meta.node_locale === polishLocale.locale) {
        return { ...meta, page: polishLocale.metaPrefix + meta.page }
      } else {
        return meta
      }
    })
    const map: Record<string, PageMetadata> = keyBy(
      allTranslatedMetas,
      (meta) => meta.page as string
    )
    return {
      ...initialState,
      pageToTitle: mapValues(map, (item) => item.title || null),
    }
  }
}
