import { createSlice } from "@reduxjs/toolkit"
import { NavigationState } from "./navigation.interface"

export const initialState: NavigationState = {
  pageToTitle: null,
}

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {},
})

export const { reducer: navigationReducer } = navigationSlice
