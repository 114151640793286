import { AuthState } from "./auth.interface"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../dto/user.object"

export const initialState: AuthState = {
  data: {
    user: null,
  },
  error: null,
  fetching: false,
  fetched: false,
}

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.data.user = action.payload
    },
    clearUser(state) {
      state.data.user = null
    },
    requestStarted(state) {
      state.fetching = true
      state.fetched = false
      state.error = null
    },
    requestFinished(state) {
      state.fetching = false
      state.fetched = true
      state.error = null
    },
    requestFailed(state, action: PayloadAction<AuthState["error"]>) {
      state.fetching = false
      state.fetched = false
      state.error = action.payload
    },
  },
})

export const authReducer = auth.reducer
export const {
  clearUser,
  requestFailed,
  requestFinished,
  requestStarted,
  setUser,
} = auth.actions
