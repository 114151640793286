import { UserAttributes } from "../../../internal-api/auth0"
import { AuthPresenter } from "../../presenters/auth.presenter"
import { setUser } from "../../index"
import { HooksAppDispatch } from "../../../store/store"

const authPresenter = new AuthPresenter()

export const login =
  (payload: UserAttributes) =>
  (dispatch: HooksAppDispatch): void => {
    const parsedUser = authPresenter.serializeToObject(payload)
    dispatch(setUser(parsedUser))
  }
