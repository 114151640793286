import React, { createContext, useContext, useState } from "react"
import { AppFunctionComponent } from "../types"

export interface ContextProps {
  validationPending: boolean
  setValidationPending: (value: boolean) => void
}

const defaultState: ContextProps = {
  validationPending: true,
  setValidationPending: () => null,
}
const PageLoaderContext = createContext(defaultState)
const usePageLoaderContext = () => useContext(PageLoaderContext)

const PageLoaderProvider: AppFunctionComponent = ({ children }) => {
  const [pending, setPending] = useState(true)

  return (
    <PageLoaderContext.Provider
      value={{
        validationPending: pending,
        setValidationPending: setPending,
      }}
    >
      {children}
    </PageLoaderContext.Provider>
  )
}

export default PageLoaderContext
export { PageLoaderProvider, usePageLoaderContext }
