import React, { useReducer, createContext, useContext } from "react"
import requestHandlingReducer, {
  ActionTypes,
} from "../reducers/request-handling.reducer"
import { AppFunctionComponent } from "../types"

export interface RequestState {
  fetching: boolean
  hasError: boolean
  status: string | null
  type?: string
  message?: string
  active: boolean
}

export interface ReducerEvent {
  type: ActionTypes
  payload?: RequestState
}

interface Context {
  state: RequestState
  dispatch: any
}

type Method = (dispatch: any, state: RequestState) => any
export type Action = ReducerEvent | Method
export type Block = () => any
export type ErrorBlock = (error: string) => any

const initialState: RequestState = {
  active: false,
  fetching: false,
  hasError: false,
  status: null,
  message: "",
}

const RequestHandlingContext = createContext({ state: initialState } as Context)
const useRequestContext = () => useContext(RequestHandlingContext)

const RequestHandlingContextProvider: AppFunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(requestHandlingReducer, initialState)

  const dispatcher = (action: Action) => {
    if (typeof action === "function") {
      return action(dispatch, state)
    } else {
      return dispatch(action)
    }
  }

  return (
    <RequestHandlingContext.Provider value={{ state, dispatch: dispatcher }}>
      {children}
    </RequestHandlingContext.Provider>
  )
}

export default RequestHandlingContext
export { RequestHandlingContextProvider, useRequestContext }
